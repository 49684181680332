@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback');

@tailwind base;
@tailwind components;

// Additional styles
@import 'additional-styles/utility-patterns.scss';
@import 'additional-styles/range-slider.scss';
@import 'additional-styles/toggle-switch.scss';
@import 'additional-styles/theme.scss';

@tailwind utilities;

// Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive
@layer utilities {
    .rtl {
        direction: rtl;
    }
}

// See Alpine.js: https://github.com/alpinejs/alpine#x-cloak
[x-cloak] {
    display: none;
}

// AOS styles
$aos-distance: 10px;
@import 'node_modules/aos/src/sass/aos.scss';

  .ck-fm input,
  .ck-fm button {
    width: 100%;
    padding: 10px;
    border: 0;
    border-radius: inherit;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }
  

  
  .ck-fm button:hover {
    transform: scale(1.02);
    opacity: 0.8;
  }

  .ck-fm h1 {
    margin-bottom: 1rem
    
  }